import React, { useEffect } from 'react'
import Categories from './Categories';
import MostViewed from './Groups/MostViewed';
import { ProductApis } from '../apis/productApis';
import { AxiosResponse } from 'axios';
import { SvgElement, icontypesEnum } from './assets/svgElement';
import { NavLink, useNavigate, useParams, useSearchParams, useLocation, Link } from 'react-router-dom';
import { FaEye, FaWhatsapp } from 'react-icons/fa';
import configs from '../configs';
import Modal from 'react-responsive-modal';
import { PaymentApis } from '../apis/paymentApis';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { login } from '../reducer/loginSlice'

//@ts-ignore
import { PhoneInput } from 'react-contact-number-input';
import { AuthApis } from '../apis/authApis';
import { CartApis } from '../apis/cartApis';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { hideAll } from 'tippy.js';
import UserSidebar from './Sidebar/UserSidebar';
import Navbar from './Navbars/Navbar';
import Sidebar from './Sidebar/Sidebar';
import { IoArrowBack } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai';
import { CategoryApis } from '../apis/CategoryApis';
//@ts-ignore
import Carousel from 'react-grid-carousel'
import Footer from './Footers/Footer';

function Deals() {

  const userLoginData = useSelector((state: any) => state.data.login.value);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const routeLocation = useLocation();

  const dispatch: Dispatch = useDispatch();

  const [products, setProducts] = React.useState<any>([]);
  const [loc, setLoc] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [category, setCategory] = React.useState(routeLocation?.state?.datas?.cat_name);
  const [fetchCategory, setFetchCategory] = React.useState('');
  const [loader, setLoader] = React.useState<boolean>(false);
  let [fullName, setFullName] = React.useState('');
  let [email, setEmail] = React.useState('');
  const [location, setLocation] = React.useState('');
  let [phoneNumber, setPhoneNumber] = React.useState<any>('');
  let [productQty, setProductQty] = React.useState<any>('');
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  let [target, setTarget] = React.useState<Element>();

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState<any>('');

  function togglePaymentModal(value2: any) {
    setvalue(value2)
    setVisible(true)
  }

  const params = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  const [cats, setCats] = React.useState<any>('');
  const [cate, setCate] = React.useState<any>('');
  React.useEffect(() => {
    CategoryApis.getCategory('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCats(response?.data?.data)
          setCate(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, []);

  const [name, setname] = React.useState('');
  const [total, setTotal] = React.useState<any>('');



  React.useEffect(() => {
    CartApis.getCartCount().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setname(response?.data?.cart);
          setTotal(response?.data?.total)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, []);

  if (searchParams.get('status') == 'cancelled') {
    navigate(`/store/${params?.storeId}`);
  }


  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      search: search,
      name: loc,
      categories: category
    };
    ProductApis.getAllDeals('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data)
          setLoader(false);
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, [category]);


  const logOut = () => {
    dispatch(login([]))
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response) {
          navigate('/');

        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  };


  // React.useEffect(() => {
  //   setLoader(true);
  //   const query: any = {
  //     search: search,
  //     name: loc,
  //     categories: category
  //   };
  //   ProductApis.getAllProducts('', query).then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setProducts(response.data)
  //         console?.log(response?.data)
  //         setLoader(false);

  //         // console.log(response?.data?.data);
  //       }
  //     }
  //   ).catch(function (error: any) {
  //     // handle error
  //     console.log(error.response.data);
  //   })

  // }, [loc]);




  const paginator = React.useCallback(
    (value: any) => {
      setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: search,
        name: loc,
        categories: category
      };
      ProductApis.getAllDeals(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data)
            setLoader(false);
            setFetchCategory(search !== '' ? search : category)
          }
        }
      ).catch(function (error: any) {
        // handle error
        console.log(error.response.data);
        console.log("new error");
      })

    }, [search, loc, category, fetchCategory]);

  return (
    <div className=''>


    <Navbar/>
     

      <div className='md:px-8 px-4'>
        <section className="md:px-4 pt-2" >

          <div className='flex flex-col'>
            <div>
              <h3 className='text-[21px] font-bold flex justify-center pb-4'>Deals</h3>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-3 '>
              {!loader ? (
                products?.products?.data?.length >= 1 ?
                  products?.products?.data?.map(
                    (data: any, index: number) => (

                      // <div>
                      <Link to={`/view-product/${(data?.id)}`} state={products?.percent} className="w-[90vw] md:w-full hover:bg-[#f1f6f9] border border-[#E6F1FC] rounded-lg p-2 cursor-pointer">
                        <p className="mb-2 tracking-tight m-2 p-2 bg-[#F4FBFF] h-44" style={{ fontSize: '16px', color: '#595959', backgroundImage: `url(${data?.product_image_1})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: 'center center' }}>{data?.link_info?.message}</p>
                        <div>
                          <h3 className='text-[14px] text-red-600 line-through pt-2'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((data?.product_price))} </h3>
                          <h3 className='text-[16px] font-semibold '>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format(data?.product_price - ((data?.product_price)*(products?.percent/100)))} </h3>
                          <h5 className="text-[14px] tracking-tight font-semibold underline text-gray-900 ">{data?.product_name}</h5>
                        </div>

                        {/* <div>
                          <span className='rounded-[2px] text-[11px] text-[#004F9A] bg-[#E6F1FC] px-1 py-1.5'>same day Delivery</span>
                        </div> */}

                      </Link>
                      // </div>

                    )
                  )
                  :

                  <div className="md:p-6 min-w-[75vw]">
                    <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-700">

                    </div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                    <div className=" bg-gray-400 rounded-full text-center text-white mb-2.5"> No Product Availabe</div>

                    <div className="flex items-center mt-4 space-x-3">

                    </div>

                  </div>

              )
                :
                <div className="p-4 rounded border border-gray-200 shadow animate-pulse md:p-6 dark:border-gray-700" style={{ height: '70vh', width: '92vw' }}>
                  <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400">

                  </div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
                  <div className="flex items-center mt-4 space-x-3">

                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
              }


            </div>

            <div className=' m-4 mt-10 flex justify-end'>
              {
                products?.products?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : `bg-['#0071BC'}] text-white`)} style={{ backgroundColor: `${datas?.active == false ? 'rgb(209 213 219' : ('#0071BC')}` }}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>

          </div>

          {/* </div> */}


        </section>
      </div>


      <Footer/>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </div>
  );
}

export default Deals;
