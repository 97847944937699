import { AxiosPromise } from "axios";
import configs from "../configs";
import { ProductsLiveApi } from "./live/productsLiveApis";



export class ProductApis {
    private static productApis: ProductsLiveApi = new ProductsLiveApi();

    static getAllProducts(pageNo:any,query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getAllProducts(pageNo,query);
        }
    } 


    static getAllDeals(pageNo:any,query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getAllDeals(pageNo,query);
        }
    } 



    


}