import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class CategoryLiveApis extends AxiosGlobal{

 
    createCategory(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/category/create-category`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    createDeals(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/deals/create-deal`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getDeals(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/deals/get-deals`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    getAllDeals(pageNo:any,query:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/deals/get-all-deals?page=${pageNo}&search=${query?.search}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    updateCategory(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/category/update-category`,data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    
    getCategory(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/category/get-category?page=${pageNo}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSingleCategory(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/category/get-single-category/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deleteCategory(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/category/delete-category/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


}