import React from "react";

// components

import CardCartPage from "../Cards/CardCartPage";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function CartPage() {
  return (
    <>
  {/* <Sidebar title="Edit Multi Link"/> */}
      
      {/* <AdminNavbar title="Edit Multi Link"/> */}
      <div className="flex flex-wrap ">
        <div className="w-full xl:w-12/12 xl:mb-0 ">
          <CardCartPage />
        </div>
     
      </div>
    </>
  );
}
