import React from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AdminApis } from '../../apis/adminApi';
import { login } from '../../reducer/loginSlice'
import Sidebar from '../Sidebar/Sidebar';
import { CategoryApis } from '../../apis/CategoryApis';
import { formatDateTime } from '../Reusable/functions';

function CardCategories() {

  const userLoginData = useSelector((state) => state.data.login.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userLists, setUserList] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  let [visible, setVisible] = React.useState(false);
  let [visible2, setVisible2] = React.useState(false);
  let [purchaseData, setPurchaseData] = React.useState([]);
  let [orderId, setOrderId] = React.useState([]);
  let [orderStatus, setOrderStatus] = React.useState([]);

  function updateStat(data) {
    setPurchaseData(data)
    setVisible2(true);

  }

  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate('/sign-in');
    }
  }
  );



  React.useEffect(() => {
    const query = {
      search: searchText,
    };
    CategoryApis.getCategory('', query).then(
      (response) => {
        if (response?.data) {
          console?.log(response?.data?.data)
          setUserList(response?.data?.data)
        } else {
          // dispatch(login([]))
        }
      }
    ).catch(function (error) {

    })
  }, []);


  const updateSinglePurchase = React.useCallback(
    (e) => {

      e.preventDefault();
      let data = {
        'status': orderStatus,
        'id': purchaseData?.id
      }

      AdminApis.updateSinglePurchase(data).then(
        (response) => {
          if (response?.data) {
            setVisible2(false)
            toast.success(response?.data?.message);
            const query = {
              search: searchText,
            };
            AdminApis.getAllPurchase('', query).then(
              (response) => {
                if (response?.data) {
                  setUserList(response?.data?.data)
                  console?.log(response?.data?.data)
                } else {
                  dispatch(login([]))
                }
              }
            )
          } else {
            toast.error(response?.response?.data?.message);
          }
        }
      ).catch(function (error) {
        toast.error(error.response.data.message);
      })
    }
    ,
    [purchaseData, orderStatus, visible2, userLists]
  );



  const paginator = React.useCallback(
    (value) => {

      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query = {
        search: searchText,
      };

      AdminApis.getAllPurchase(value2, query).then(
        (response) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);


  const updatePurchase = React.useCallback(
    (e) => {

      e.preventDefault();
      let data = {
        'status': orderStatus,
        'order_id': orderId
      }

      AdminApis.updatePurchases(data).then(
        (response) => {
          if (response?.data) {
            setVisible(false)
            toast.success(response?.data?.data?.message);


            const query = {
              search: searchText,
            };
            AdminApis.getAllPurchase('', query).then(
              (response) => {
                if (response?.data) {
                  setUserList(response?.data?.data)
                  console?.log(response?.data?.data)
                } else {
                  dispatch(login([]))
                }
              }
            )
          } else {
            toast.error(response?.response?.data?.message);
          }
        }
      ).catch(function (error) {
        toast.error(error.response.data.message);
      })
    }
    ,
    [orderId, orderStatus, visible, userLists]
  );


  return (
    <>


      <div className="bg-white">
        <div className='py-4 lg:px-10 px-6 '>
          <h1 className='text-[30px] font-semibold'>Categories</h1>
          <div className='flex justify-end'>
            <div className=" mr-3 flex justify-end">
              <div className="relative flex w-full flex-wrap items-stretch">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                </div>
                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
              </div>

              <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div>
              <div className='mt-0.5 ml-2'><NavLink to={'/admin/create-category'}>
                <button type='button' onClick={(e) => setVisible(true)} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Create</button>
              </NavLink>

              </div>



            </div>

            {/* <div className='mt-1'>Filter </div> */}
          </div>




          <div className="shadow-lg sm:rounded-lg mt-6">
            <table className=" text-sm text-gray-500 ">
              <div className='w-full overflow-auto'>
                <thead className="text-xs text-gray-700 bg-gray-50 ">
                  <tr>
                    <th scope="" className="px-20 py-3">
                      S/N
                    </th>
                    <th scope="" className="px-20 py-3">
                      Category Name
                    </th>
                    <th scope="" className="px-20 py-3">
                      Date Created
                    </th>
                    <th scope="" className="px-20 py-3">
                      Action
                    </th>

                  </tr>
                </thead>

                <tbody className=''>
                  {
                    userLists?.data?.map(
                      (datas, index) => (
                        <tr className="bg-white  ">

                          <td className="px-20 py-4">
                            {index + 1}
                          </td>

                          <td className="px-20 py-4">
                            {datas?.cat_name}
                          </td>
                          <td className="px-20 py-4">
                            {formatDateTime(datas?.created_at)}
                          </td>


                          <td className="px-20 py-4 text-blue-500 underline">
                            <NavLink to={`/edit-category/${datas?.id}`}>
                              Edit
                            </NavLink>

                          </td>

                        </tr>
                      )
                    )
                  }

                </tbody>
              </div>
            </table>

          </div>
          <div className='m-4 flex justify-end'>
            {
              userLists?.links?.filter(((item, idx) => idx < 1000)).map(
                (datas, index) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#0071BC] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>




        </div>

      </div>




      <section>
        <Modal
          visible={visible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setVisible(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>
            <span className="flex justify-end pr-2 pt-2">
              <p className="cursor-pointer font-bold" onClick={(e) => setVisible(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
            </span>
            <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">

              <div className="">

                <span>Update Purchase</span>

                <form onSubmit={updatePurchase}>
                  <div className="grid md:grid-cols-2 gap-2">
                    <div>
                      <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Purchase Id</label>
                      <input required type="text" onChange={(e) => setOrderId(e.target.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
                    </div>

                    <div>
                      <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Status</label>
                      <select id="gender" required onChange={(e) => setOrderStatus(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                        <option selected value="">Select Status</option>
                        <option value="paid">Paid</option>
                        <option value="delievered">Delievered</option>
                        <option value="rejected">Rejected</option>
                      </select>

                    </div>

                  </div>

                  <span className="flex justify-center pt-8">
                    <button
                      type="submit"

                      style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                      className=" text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>



      <section>
        <Modal
          visible={visible2}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => setVisible2(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>
            <span className="flex justify-end pr-2 pt-2">
              <p className="cursor-pointer font-bold" onClick={(e) => setVisible2(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
            </span>
            <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">

              <div className="">

                <span>Update Purchase</span>

                <form onSubmit={updateSinglePurchase}>
                  <div className="grid md:grid-cols-2 gap-2">


                    <div>
                      <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Select Status</label>
                      <select id="gender" required onChange={(e) => setOrderStatus(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                        <option selected value="">Select Status</option>
                        <option value="paid">Paid</option>
                        <option value="delievered">Delievered</option>
                        <option value="rejected">Rejected</option>
                      </select>

                    </div>

                  </div>

                  <span className="flex justify-center pt-8">
                    <button
                      type="submit"

                      style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                      className=" text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Update
                    </button>
                  </span>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default CardCategories