import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
// import { AuthApis } from "../../../apis/authApis";
// import { SvgElement, icontypesEnum } from "../../assets/svgElement";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthApis } from "../../apis/authApis";

function ForgotPword() {
  const navigate = useNavigate();

  const [userData, setUserdata] = useState({
    'email': "",
  });
  
  const [timer, setTimer] = useState(60);
  
  const [loader, setLoader] = React.useState<any>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('email', userData?.email)
    AuthApis.forgotPassword(formData).then(
      (response) => {
        if (response?.data) {
          if (response?.data?.success) {
            
            const newTimerValue = 60;
            setTimer(newTimerValue);
            localStorage.setItem('resendOTPTimer', String(Math.floor(Date.now() / 1000) + newTimerValue));
            localStorage.setItem('OTPEmail', userData?.email);

            toast.success("OTP Sent Successfully");
            
            navigate('/otp-verify');
            setLoader(false)
          }
        } else {
          toast.warn(response?.data?.data);
          setLoader(false)
        }
        toast.warn(response?.data?.data);
          setLoader(false)

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      setLoader(false)
      toast.warn('Invalid Credentials');
      console.log(error.response.data);
      // toast.error("Offfline");
    }).finally(() => {
      setLoader(false)
      // toast.error("No Internet Connection");

    });
  }
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] min-h-screen pt-16 pb-32 ">
        <div className="container flex flex-col md:justify-center mx-auto items-center rounded-lg p-6 md:max-w-3xl">

          <div>
            <div className='flex justify-center'>
              <img src="./images/logo.png" className="" alt="mart Logo" />
            </div>
            <h1 className="mt-6 text-[#000] lg:text-[32px] text-[28px] font-semibold text-center"> Forgot Password?</h1>
            <p className='mt-4 text-center text-[#00000080] text-[14px] font-normal'> No worries, we’ll send you reset instructions.</p>
          </div>


          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                  <div className="flex-auto   py-10 pt-0">
                    <div className="flex flex-wrap ">
                      <div className="w-full lg:w-12/12 ">
                        <div className="relative w-full mb-6">
                          <label className="block mb-2 text-sm font-semibold text-[#414143]">
                            Email Address*
                          </label>
                          <input
                            type="email"
                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                            placeholder="Enter Email"
                            name="email"

                            required
                            onChange={handleChange}

                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="w-full text-white bg-[#027DCB] hover:bg-[#027DCB] font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2"
                      >
                        <span className='flex justify-center'>
                          <span>  Reset Password</span>
                          <span>
                            <Oval
                              visible={loader}
                              height="20"
                              width="80"
                              color="#00B07B"
                              secondaryColor="#E6F1FC"
                              ariaLabel="oval-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </span>

                        </span>

                      </button>

                      <NavLink to={"/sign-in"} className="flex justify-center">
                        {/* <SvgElement type={icontypesEnum.BARS} /> */}
                        <p className="text-center text-xs font-semibold text-[#027DCB] mt-1">

                          Back to login

                        </p>
                      </NavLink>

                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>

        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- End block --> */}
    </>
  );
}

export default ForgotPword;
