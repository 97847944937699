import React, { useEffect } from "react";

// components

import CardWallet from "../Cards/CardWallet";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbars/Navbar";
import UserSidebar from "../Sidebar/UserSidebar";
import Footer from "../Footers/Footer";


export default function Wallet() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="flex justify-start md:mt-10 mt-3">

        <div className="hidden md:flex md:w-1/5">
          <UserSidebar title="Purchase List" />
        </div>

        <div className="md:w-4/5 w-full md:px-10 ">
        <CardWallet />
        </div>
       

      </div>
      <Footer/>



      {/* 
      <Sidebar title="Profile"/>
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Profile" />
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-10/12 mb-12 xl:mb-0 px-4">
            <CardUpdateProfile />
          </div>
        </div>
      </div> */}
    </>
  );
}
