import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footers/Footer'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className='bg-[#027DCB]  pt-10 pb-10'>
                <div className=' lg:px-14 px-8 lg:mx-20 mx-4 bg-white rounded-[30px] pt-8 pb-10'>
                    <h1 className='lg:text-[20px] text-[18px] font-extrabold text-center'>MmartPlus Privacy Policy</h1>
                    <p className='text-center pt-3'>Last updated: June 10, 2024</p>
                    <p className='text-center pt-2'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                    </p>
                    <p className='text-center pt-5'>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                    <hr className='my-4' />

                    <div>
                        <div className='flex space-x-3'>
                            <img src="/images/icons-01.png" className="w-[40px]" alt="icons" />
                            <h1 className='lg:text-[20px] text-[16px] font-extrabold mt-4'>Interpretation and Definitions</h1>
                        </div>

                        <div className='pl-8 pt-5'>
                            <div className='flex space-x-3 '>
                                <img src="/images/icons-02.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-medium mt-4'>Interpretation</h1>

                            </div>
                            <p className='pt-3'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                            </p>
                            <hr className='my-8' />
                        </div>

                        <div className='pl-8 pt-5'>
                            <div className='flex space-x-3 '>
                                <img src="/images/icons-03.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-medium mt-4'>Definitions</h1>
                            </div>
                            <p className='pt-2 text-gray-500'>For the purposes of this Privacy Policy:</p>
                            <div className='pt-6 pl-4'>
                                <ul className='list-disc list-outside space-y-2'>
                                    <li><span className=' font-bold'>You</span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                        Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.</li>
                                    <li><span className='font-bold'>Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to mmartplus, Morrobento av 21 de janeiro Luanda. <br />
                                        For the purpose of the GDPR, the Company is the Data Controller.
                                    </li>
                                    <li><span className='font-bold'>Application</span> means the software program provided by the Company downloaded by You on any electronic device, named mmartplus.</li>
                                    <li><span className='font-bold'>Affiliate</span> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                                    <li><span className='font-bold'>Account</span> means a unique account created for You to access our Service or parts of our Service.</li>
                                    <li><span className='font-bold'>Service</span> refers to the Application.</li>
                                    <li><span className='font-bold'>Country</span> refers to: Angola and/or Nigeria.</li>
                                    <li><span className='font-bold'>Service Provider</span> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
                                    <li><span className='font-bold'>Third-party</span> Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
                                    <li><span className='font-bold'>Facebook Fan Page</span>  is a public profile named mmartplus Africa specifically created by the Company on the Facebook social network, accessible from
                                        <Link to="/" className='text-[#027DCB] cursor-pointer'> web.facebook.com/mmartplus</Link>
                                    </li>
                                    <li><span className='font-bold'>Personal Data</span> is any information that relates to an identified or identifiable individual.
                                        For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</li>
                                    <li><span className='font-bold'>Device</span> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                                    </li>
                                    <li><span className='font-bold'>Usage Data</span> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                                    </li>
                                    <li><span className='font-bold'>Data Controller,</span> for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</li>

                                </ul>
                            </div>

                            <hr className='my-8' />
                        </div>
                    </div>

                    <div>
                        <div className='flex space-x-3'>
                            <img src="/images/icon-04.png" className="w-[40px]" alt="icons" />
                            <h1 className='lg:text-[20px] text-[16px] font-black mt-4'>Collecting and Using Your Personal Data</h1>
                        </div>
                        <div className='flex items-center space-x-3 pt-4'>
                            <img src="/images/icon-05.png" className="w-[40px]" alt="icons" />

                            <h1 className='lg:text-[18px] text-[14px] font-medium '>Collection of Information</h1>
                        </div>
                        <div className='pl-8 pt-5'>
                            <p className='text-[14px]'>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                            <div className='pt-4'>
                                <div className='flex space-x-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M20 7L10 17l-5-5" /></svg>
                                    <h5 className='text-[18px]'>Information you provide to us when you register for an account or use the app, such as your name, email address, mobile phone number, and payment information.</h5>
                                </div>
                                <div className='flex space-x-3 pt-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M20 7L10 17l-5-5" /></svg>
                                    <h5 className='text-[18px]'>Information you provide to us when you upload pictures of products you want, such as product name, brand, and quantity.</h5>
                                </div>
                                <div className='flex space-x-3 pt-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M20 7L10 17l-5-5" /></svg>
                                    <h5 className='text-[18px]'>Information we collect automatically when you use the app, such as device information, IP address, location information, and usage data.</h5>
                                </div>
                                <div className='flex space-x-3 pt-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M20 7L10 17l-5-5" /></svg>
                                    <h5 className='text-[18px]'>Information we receive from third-party login providers such as Facebook, Google, and Apple, when you choose to sign in using their service.</h5>
                                </div>
                                <div className='flex space-x-3 pt-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M20 7L10 17l-5-5" /></svg>
                                    <h5 className='text-[18px]'>App Usage & behavior and Order/Purchase Data History</h5>
                                </div>

                            </div>
                        </div>
                        <hr className='my-8' />
                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-06.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Data Usage</h1>
                            </div>
                            <div className='pl-8 pt-5'>
                                <p >Usage Data is collected automatically when using the Service.</p>
                                <p className='pt-5'>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                                <p className='pt-5'>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                                <p className='pt-5'>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                                <p className='pt-5'>We may use the information we collect from you for the following purposes:</p>
                                <p className='pt-2'>- To provide and improve our services, including processing orders and delivering groceries</p>
                                <p className='pt-2'>- To communicate with you about your orders, account, and promotions</p>
                                <p className='pt-2'>- To personalize your experience and improve the app's functionality.To comply with applicable laws, regulations, and legal processes</p>

                                <h3 className='pt-4 font-bold'>Disclosure of Information</h3>

                                <p className='pt-2'>We may disclose your personal information to the following types of third parties:</p>
                                <p className='pt-2'>- Service providers who assist us in providing and improving our services, such as delivery partners and payment processors</p>
                                <p className='pt-2'>- Advertising partners who help us to deliver personalized ads</p>
                                <p className='pt-2'>- Third-party login providers such as Facebook, Google, and Apple, who help you sign in to our app</p>
                                <p className='pt-2'>- Law enforcement or government authorities if required by law.</p>
                                {/* <p className='pt-5'></p>
                                <p className='pt-5'></p>
                                <p className='pt-5'></p>
                                <p className='pt-5'></p>
                                <p className='pt-5'></p>
                                <p className='pt-5'></p>
                                <p className='pt-5'></p> */}
                            </div>
                            <hr className='my-8' />
                        </div>

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-07.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Information from Third-Party Social Media Services</h1>
                            </div>
                            <div>
                                <p className='pt-2 text-gray-500'>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
                                <div className='pt-6 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Google</li>
                                        <li>Facebook</li>
                                        <li>Phone Number</li>
                                    </ul>
                                </div>

                                <p className='pt-5'>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service. We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
                                <p className='pt-5'>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>

                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-08.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black '>Information Collected while Using the Application</h1>
                            </div>
                            <div>
                                <p className='pt-2 text-[14px]'>While using Our Application, in order to provide features of Our Application, We may collect, with your prior permission:</p>
                                <div className='pt-6 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Information regarding your location.</li>

                                    </ul>
                                </div>

                                <p className='pt-5'>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply stored on Your device.
                                    You can enable or disable access to this information at any time, through Your Device settings.</p>
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-09.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Use of Your Personal Data</h1>
                            </div>
                            <div>
                                <p className='pt-2 text-[14px]'>The Company may use Personal Data for the following purposes:</p>
                                <div className='pt-6 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>To provide and maintain our Service, including to monitor the usage of our Service.
                                        </li>
                                        <li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                                        <li>For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                                        <li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                                        <li>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                                        <li>To manage Your requests: To attend and manage Your requests to Us.
                                            We may share your personal information in the following situations:</li>
                                        <li>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to advertise on third party websites to You after You visited our Service, for payment processing, to contact You.</li>
                                        <li>For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company</li>
                                        <li>With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                                        <li>With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                                        <li>With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-10.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black '>Retention of Your Personal Data</h1>
                            </div>
                            <div>
                                <p className='pt-5 '>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                                <p className='pt-5 '>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-11.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black '>Transfer of Your Personal Data</h1>
                            </div>
                            <div>
                                <p className='pt-5 '>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                                <p className='pt-5 '>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                            <p className='pt-5'>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-11.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Disclosure of Your Personal Data</h1>

                            </div>
                            <div>
                                <h3 className='lg:text-[17px] text-[12px] mt-4'>Business Transactions</h3>
                                <p className='pt-5 '>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                                
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-12.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Law enforcement</h1>

                            </div>
                            <div>  
                                <p className='pt-5 '>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>  
                            </div>
                            <div className='flex items-center space-x-3 pt-4'>
                                <img src="/images/icons-13.png" className="w-[40px]" alt="icons" />

                                <h1 className='lg:text-[18px] text-[14px] font-black'>Other legal requirements</h1>

                            </div>
                            <div>  
                                <p className='pt-4 '>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>  
                            </div>
                            <div className='pt-6 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Comply with a legal obligation</li>
                                        <li>Protect and defend the rights or property of the Company</li>
                                        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                                        <li>Protect the personal safety of Users of the Service or the public</li>
                                        <li> Protect against legal liability.</li>
                                    </ul>
                                    </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-12.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black'>Security of Your Personal Data</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>            
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-13.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black'>Detailed Information on the Processing of Your Personal Data
                                </h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
                                </p>            
                            </div>
                            <div  className='flex space-x-3 items-center pt-4'>
                            <img src="/images/icon-14.png" className="w-[40px]" alt="icons" />
                            <h3 className='lg:text-[17px] text-[12px] mt-4'>Analytics</h3>
                                </div>
                                <p className='pt-5 '>We may use third-party Service providers to monitor and analyze the use of our Service.</p>

                                                          <div className='pt-6 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li><span className='font-bold pb-2'>Google Analytics</span>
                                            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                                         <br />   ‍You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:</p>
                                       <a className='text-blue-400' href='https://policies.google.com/privacy' >policies.google.com/privacy</a>
                                        <br />
                                        <br />
                                        <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: </p>
                                        <a className='text-blue-400' href='https://policies.google.com/privacy' >policies.google.com/privacy</a>
                                       
                                        </li>

                                        <li><span className='font-bold pb-2'>Mixpanel</span>
                                            <p>Mixpanel is provided by Mixpanel Inc.
                                          <br />  You can prevent Mixpanel from using your information for analytics purposes by opting-out.
                                          <br /> To opt-out of Mixpanel service, please visit this page: </p>
                                       <a className='text-blue-400' href='https://mixpanel.com/optout/' >mixpanel.com/optout</a>
                                        <br />
                                        <br />
                                        <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel:  </p>
                                        <a className='text-blue-400' href='https://mixpanel.com/terms/' >mixpanel.com/terms</a>
                                       
                                        </li>

                                        <li><span className='font-bold pb-2'>Firebase</span>
                                            <p>Firebase is an analytics service provided by Google Inc.
<br />You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy
<br />We also encourage you to review the Google's policy for safeguarding your data: 
 </p>
                                       <a className='text-blue-400 text-sm' href='https://support.google.com/analytics/answer/6004245' >support.google.com/analytics/answer/6004245</a>
                                        <br />
                                        <br />
                                        <p>For more information on what type of information Firebase collects, please visit the Google Privacy & Terms web page:  </p>
                                        <a className='text-blue-400' href='https://policies.google.com/privacy' >policies.google.com/privacy</a>
                                       
                                        </li>

                                        <li><span className='font-bold pb-2'>Moengage</span>
                                     <br />   <a className='text-blue-400' href='https://sdk-01.moengage.com/' >sdk-01.moengage.com</a>

                                            <p>We use Moengage SDK  and we share Primary Account information to 
                                                <br />

<a className='text-blue-400' href='https://sdk-01.moengage.com/' >sdk-01.moengage.com</a>

 </p>
                                      
                                        <p>

in our app; your email, phone number, and other personal information will be passed to Moengage in order to allow us to send you notifications about your orders, account, and marketing material such as coupons. For more information on what type of information Moengage collects, please visit the Terms of Use page of Moengage: 
</p>
                                        <a className='text-blue-400' href='https://www.moengage.com/privacy-policy/' >www.moengage.com/privacy-policy</a>
                                       
                                        </li>
                                      </ul>
                                    </div>  
                           
                        </div>

                        <hr className='my-8' />


                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-16.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px]  '>Payments</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>
                                We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).
                                <br />
                                <br />
                                We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                                </p>            
                            </div>
                        </div>

                        <hr className='my-8' />


                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-17.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px]  '>Usage, Performance and Miscellaneous</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>We may use third-party Service Providers to provide better improvement of our Service. </p>            
                            </div>
                        </div>

                        <hr className='my-8' />
                    </div>


                    <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-18.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black'>GDPR Privacy
                                </h1>
                            </div>
                            {/* <div>           
                                <p className='pt-5 '>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
                                </p>            
                            </div> */}
                            <div  className='flex space-x-3 items-center pt-4'>
                            <img src="/images/icon-19.png" className="w-[40px]" alt="icons" />
                            <h3 className='lg:text-[17px] text-[12px] mt-4'>Legal Basis for Processing Personal Data under GDPR</h3>
                                </div>
                                <p className='pt-3 '>We may process Personal Data under the following conditions:</p>

                                                          <div className='pt-3 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li><span className='font-bold pb-1'>Consent:</span>
                                        You have given Your consent for processing Personal Data for one or more specific purposes.

                                        </li>

                                        <li><span className='font-bold pb-1'>Performance of a contract:</span>
                                        Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
                                        </li>

                                        <li><span className='font-bold pb-1'>Legal obligations:</span>
                                        Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.

                                        </li>

                                        <li><span className='font-bold pb-1'>Vital interests:</span>
                                        Processing Personal Data is necessary in order to protect Your vital interests or of another natural person
                                        </li>

                                        <li><span className='font-bold pb-1'>Public interests:</span>
                                        Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.

                                      </li>

                                      <li><span className='font-bold pb-1'>Legitimate interests:</span>
                                      Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company. In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract
                                      </li>
                                      </ul>
                                    </div>  
                           
                        </div>

                        <hr className='my-8' />


                        <div>
                            {/* <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-18.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black'>GDPR Privacy
                                </h1>
                            </div> */}
                            {/* <div>           
                                <p className='pt-5 '>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
                                </p>            
                            </div> */}
                            <div  className='flex space-x-3 items-center pt-4'>
                            <img src="/images/icon-20.png" className="w-[40px]" alt="icons" />
                            <h3 className='lg:text-[17px] text-[12px] mt-4'>Your Rights under the GDPR</h3>
                                </div>
                                <p className='pt-3 '>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.
                            <br />    You have the right under this Privacy Policy to:</p>

                                                          <div className='pt-3 pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li><span className='font-bold pb-1'>Request access to Your Personal Data.</span>
                                        The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
                                        </li>

                                        <li><span className='font-bold pb-1'>Request correction of the Personal Data that We hold about You.</span>
                                        You have the right to to have any incomplete or inaccurate information We hold about You corrected.
Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.

                                        </li>

                                        <li><span className='font-bold pb-1'>Request erasure of Your Personal Data.</span>
                                        You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
                                        </li>

                                        <li><span className='font-bold pb-1'>Request the transfer of Your Personal Data.</span>
                                        We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.

                                        </li>

                                        <li><span className='font-bold pb-1'>Public interests:</span>
                                        Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.

                                      </li>

                                      <li><span className='font-bold pb-1'>Withdraw Your consent.</span>
                                      You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.

                                      </li>
                                      </ul>
                                    </div>  
                           
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-21.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px]  '>Exercising of Your GDPR Data Protection Rights</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                                     Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.</p>            
                            </div>
                        </div>

                        <hr className='my-8' />


                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-22.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black'>Facebook Fan Page
                                </h1>
                            </div>
                            {/* <div>           
                                <p className='pt-5 '>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
                                </p>            
                            </div> */}
                            <div  className='flex items-center space-x-3 items-center pt-4'>
                            <img src="/images/icon-23.png" className="w-[40px]" alt="icons" />
                            <h3 className='lg:text-[17px] text-[12px] '>Data Controller for the Facebook Fan Page</h3>
                                </div>
                                <p className='pt-3 '>
                                The Company is the Data Controller of Your Personal Data collected while using the Service. As operator of the Facebook Fan Page 
<br /> <a className='text-blue-400' href='https://www.facebook.com/Mano-Africa-103163848347989' >facebook.com/Mano-Africa-103163848347989</a>
, <br />the Company and the operator of the social network Facebook are Joint Controllers.
                                <br/>
                                <br/>
                                The Company has entered into agreements with Facebook that define the terms for use of the Facebook Fan Page, among other things. These terms are mostly based on the Facebook Terms of Service: 
                                <br /> <a className='text-blue-400' href='https://www.facebook.com/terms.php' >facebook.com/terms.php</a>
                                <br />
                                <br />
                                Visit the Facebook Privacy Policy 
                                <br /> <a className='text-blue-400' href='https://www.facebook.com/policy.php' >www.facebook.com/policy.php</a>
 <br />for more information about how Facebook manages Personal data or contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.

      
                                
                                </p>

                               
                           
                        </div>

                        <hr className='my-8' />


                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-24.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px]  '>Facebook Insights</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>
                                We use the Facebook Insights function in connection with the operation of the Facebook Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data about Our users.
‍<br />
‍<br />
For this purpose, Facebook places a Cookie on the device of the user visiting Our Facebook Fan Page. Each Cookie contains a unique identifier code and remains active for a period of two years, except when it is deleted before the end of this period.
Facebook receives, records and processes the information stored in the Cookie, especially when the user visits the Facebook services, services that are provided by other members of the Facebook Fan Page and services by other companies that use Facebook services.
‍<br />
‍<br />
‍
For more information on the privacy practices of Facebook, please visit Facebook Privacy Policy here: 
‍<br />
<a className='text-blue-400' href='https://www.facebook.com/full_data_use_policy' >www.facebook.com/full_data_use_policy</a>

                                    </p>            
                            </div>
                        </div>

                        <hr className='my-8' />


                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-25.svg" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black '>Facebook Insights</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>
                                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
‍<br />
‍<br />
‍
If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
‍<br />
‍<br />
‍
Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.
                                    </p>            
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-26.png" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black '>Changes to this Privacy Policy</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>
                                We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
‍<br />
‍<br />
‍
We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
‍<br />
‍<br />
‍
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                                    </p>            
                            </div>
                        </div>

                        <hr className='my-8' />

                        <div>
                            <div className='flex items-center space-x-3 pt-2'>
                                <img src="/images/icon-27.svg" className="w-[40px]" alt="icons" />
                                <h1 className='lg:text-[18px] text-[14px] font-black '>Contact Us</h1>
                            </div>
                            <div>           
                                <p className='pt-5 '>
                                If you have any questions about this Privacy Policy, You can contact us: 
                                <br />By email: 

‍<br />
‍<a className='text-blue-400' href='mailto:info@mmartplus.com' >info@mmartplus.com</a> </p>            
                            </div>
                        </div>
                </div>

            </div>
<div className='-mt-10'>
<Footer />
</div>

        </>
    )
}

export default PrivacyPolicy