import React, { useEffect } from "react";

// components

import CardViewProductDetailsPage from "../Cards/CardViewProductDetailsPage";
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";

export default function ViewProductDetailsPage() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <>
  {/* <Sidebar title="Edit Multi Link"/> */}
      
      {/* <AdminNavbar title="Edit Multi Link"/> */}
      <div className="flex flex-wrap ">
        <div className="w-full xl:w-12/12 xl:mb-0 ">
          <CardViewProductDetailsPage />
        </div>
     
      </div>
    </>
  );
}
