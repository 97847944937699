import React from 'react'
// import { AuthApis } from '../../../apis/authApis';
// import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {

  const [userData, setUserdata] = React.useState({
    'email': "",
    'otp': "",
    'password': "",
    "confirm_password": ""
  });
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(userData?.password !== userData?.confirm_password){
      toast.warn('Password does not match');
    }else{
      const formData = new FormData()
      formData.append('email', location?.state?.email)
      formData.append('token', userData?.otp)
      formData.append('password', userData?.password)
      formData.append('password_confirmation', userData?.confirm_password)
  
   
    
  }
  }

  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FBFBFB] min-h-screen pt-16 pb-32 ">
        <div className="container flex flex-col md:justify-center mx-auto items-center rounded-lg p-6 md:max-w-3xl">
        
     
        <div>
            <div className='flex justify-center'>
              <img src="./images/logo.png" className="" alt="mart Logo" />
            </div>
            <h1 className="mt-6 text-[#000] lg:text-[32px] text-[28px] font-semibold text-center"> Password Reset</h1>
            <p className='mt-4 text-center text-[#00000080] text-[14px] font-normal'>  Must be at least 8 charaters.</p>
          </div>
         
          
       
            <div>
            <form >
              <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                  <div className="flex-auto   py-10 pt-0">
                    <div className="flex flex-wrap ">
                      <div className="w-full lg:w-12/12 ">
                        <div className="relative w-full mb-6">
                          <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          We have sent your 4 digit code to your email
                          </label>
                          <input
                            type="number"
                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                            placeholder="Enter Your 4 Digit Code"
                            name="email"
                            required
                          //   onChange={handleChange}

                          />
                        </div>
                      </div>
                      <hr className='mt-10' />

                      <div className="w-full lg:w-12/12 ">
                        <div className="relative w-full mb-6">
                          <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          New Password
                          </label>
                          <input
                            type="password"
                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                            placeholder="*****"
                            name="email"
                            required
                          //   onChange={handleChange}

                          />
                        </div>
                      </div> 
                      <div className="w-full lg:w-12/12 ">
                        <div className="relative w-full mb-6">
                          <label className="block mb-2 text-sm font-semibold text-[#414143]">
                          Confirm Password
                          </label>
                          <input
                            type="password"
                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                            placeholder="*****"
                            name="email"
                            required
                          //   onChange={handleChange}

                          />
                        </div>
                      </div>  

                      <button
                        type="submit"
                        className="w-full text-white bg-[#027DCB] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                      >
                        Reset Password
                      </button>

                      <NavLink to={"/sign-in"} className="flex justify-center">
                        {/* <SvgElement type={icontypesEnum.BARS} /> */}
                        <p className="text-center text-xs font-semibold text-[#027DCB] mt-1">

                          Back to login

                        </p>
                      </NavLink>

                    </div>
                  </div>
                </div>
              </div>
  
            </form>
          </div>



        </div>
        <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default ResetPassword;