import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../reducer/loginSlice'
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../apis/live/authLiveApis";
import { AuthApis } from "../../apis/authApis";
import { store } from "../../store/store";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbars/Navbar";
import { useLoadScript, StandaloneSearchBox, LoadScriptProps } from '@react-google-maps/api';


const libraries: LoadScriptProps['libraries'] = ["places"];


function Register() {

    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState<any>("");
    const [address, setAddress] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const initialValue = {
        email: "",
        fullName: "",
        phoneNo: "",
        gender: "",
        password: "",
        comfirmPassword: "",
        phone_number: "",
        checked: false,
    };

    const [userData, setUserdata] = useState({
        'first_name': "",
        'last_name': "",
        'gender': "",
        'email': "",
        'password': "",
        'confirm_password': "",
        'phone_number': "",
        "address": "",
        'checked': false
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        setUserdata({ ...userData, [name]: value });
    };


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    // How to access the redux store
    const userLoginData = useSelector((state: any) => state.data.login.value);

    // This is used to update the store
    const dispatch: Dispatch = useDispatch();

    const handleSubmit = React.useCallback(
        (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (userData?.password !== userData?.confirm_password) {
                return toast.error("Password does not match");
            }

            const formData = new FormData()
            formData.append('first_name', userData?.first_name)
            formData.append('last_name', userData?.last_name)
            formData.append('gender', userData?.gender)
            formData.append('email', userData?.email)
            formData.append('address', address)
            formData.append('phone_number', userData?.phone_number)
            formData.append('password', userData?.password)

            AuthApis.register(formData).then(
                (response: any) => {
                        
                    if (response?.data?.status === true) {
                        dispatch(login({ email: userData?.email, token: response.data.token, name: response.data.name }))
                        toast.success("Login Successful");
                        navigate('/verify-email');
                    } else {
                        toast.error('Information already exist.')
                    }

                    toast.success(response?.data?.message);
                }
            ).catch(function (error) {
                // handle error
                console.log(error);
                toast.error("Offfline");
            })
        },
        [userData, phone,address]
    );


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyA8uPmEdNIXnG7TXLMf2iECVp5wAmXczeY', // Replace with your actual API key
        libraries,
    });

    
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

    if (!isLoaded) return <div>Loading...</div>;
    if (loadError) return <div>Error loading maps</div>;

    const handleLoad = (searchBox: google.maps.places.SearchBox) => {
        searchBoxRef.current = searchBox;
    };

    const handlePlacesChanged = () => {
        const places = searchBoxRef.current?.getPlaces();
        if (places && places.length > 0) {
            setAddress(places[0].formatted_address || '');
            setSuggestions([]);
        }
    };

    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setAddress(input);

        if (input.length > 2) {
            const autocompleteService = new google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                }
            });
        } else {
            setSuggestions([]);
        }
    };


    return (
        <div>
            <div className=" bg-[#FBFBFB] pt-14 ">
                <div className="container flex flex-col md:justify-center mx-auto items-center rounded-lg p-6 md:max-w-3xl">
                    <div>
                        <NavLink className="text-center flex justify-center" to={'/'}>
                            <img src="./images/logo.png" className="text-center flex justify-center" alt="mart Logo" />
                        </NavLink>
                        <h1 className=' mt-6 text-[#027DCB] lg:text-[32px] text-[28px] font-semibold'>Create your account</h1>
                        <p className='text-center text-[#00000080] text-[14px] font-normal'>Enter the fields below to get started</p>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                    <div className="flex-auto   py-10 pt-0">
                                        <div className="flex flex-wrap ">

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6 mt-4">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        First Name*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="Enter First Name"
                                                        name="first_name"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 ">
                                                <div className="relative w-full mb-6 md:mt-4 mt-0">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Last Name*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="Enter Last Name"
                                                        name="last_name"
                                                        required
                                                        onChange={handleChange}

                                                    />
                                                </div>
                                            </div>


                                            <div className="w-full lg:w-12/12 ">
                                                <div className="relative w-full mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Email Address*
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        required
                                                        onChange={handleChange}

                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Gender*
                                                    </label>

                                                    <select id="gender" name='gender' onChange={(e: any) => handleChange(e)} className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                                        <option selected>Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>



                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12">
                                                <div className="relative mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Phone No*
                                                    </label>

                                                    <input
                                                        type="number"
                                                        className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                        placeholder="Enter Phone Number"
                                                        name="phone_number"
                                                        required
                                                        onChange={handleChange}

                                                    />

                                                </div>
                                            </div>


                                            <div className="relative w-full lg:w-12/12">
                                                <StandaloneSearchBox
                                                    onLoad={handleLoad}
                                                    onPlacesChanged={handlePlacesChanged}
                                                >
                                                        <div className="relative mb-6">
                                                            <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                                Address
                                                            </label>

                                                            <textarea
                                                                className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                                                placeholder="Enter Delivery Address"
                                                                name="address"
                                                                required
                                                                value={address}
                                                                onChange={(e: any) => handleInputChange(e)}
                                                            />

                                                        </div>
                                                </StandaloneSearchBox>
                                                {suggestions.length > 0 && (
                                                    <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                                                        {suggestions.map((suggestion) => (
                                                            <li
                                                                key={suggestion.place_id}
                                                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                                onClick={() => {
                                                                    setAddress(suggestion.description);
                                                                    setSuggestions([]);
                                                                }}
                                                            >
                                                                {suggestion.description}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            <div className="w-full lg:w-6/12 lg:pr-4">
                                                <div className="relative w-full mb-6">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Password*
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px] ease-linear transition-all duration-150"
                                                            placeholder="Enter Password"
                                                            name="password"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <span
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            className="absolute right-3 top-2.5 cursor-pointer"
                                                        >
                                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12">
                                                <div className="relative w-full mt-6 md:mt-0">
                                                    <label className="block mb-2 text-sm font-semibold text-[#414143]">
                                                        Confirm Password*
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            type={showConfirmPassword ? "text" : "password"}
                                                            className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px] ease-linear transition-all duration-150"
                                                            placeholder="Confirm Password"
                                                            name="confirm_password"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <span
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            className="absolute right-3 top-2.5 cursor-pointer"
                                                        >
                                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-center'>
                                <button
                                    type="submit"
                                    className="w-[70%] text-white bg-[#027DCB]  font-medium rounded-[5px] text-sm px-5 py-3 mr-2 mb-2"
                                >
                                    Create Account
                                </button>
                            </div>

                            <p className="text-center text-[#0A0A0C] mt-1 text-[15px] font-semibold">
                                Already have an account?{" "}
                                <NavLink to="/sign-in">
                                    <span className="text-[#027DCB] cursor-pointer font-bold">Login</span>
                                </NavLink>
                            </p>
                        </form>
                    </div>

                </div>
            </div>


            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    )
}

export default Register