import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class CartLiveApis extends AxiosGlobal{

 
    createCart(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/cart/create-cart`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    createWishlist(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/wishlist/create-wishlist`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllList(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/wishlist/get-wishlist?page=${pageNo}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    deleteWishlist(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/wishlist/delete-wishlist/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getLocation(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/location/getLocation`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    submitFeedback(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/feedback`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }



    updateCart(data: any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/cart/update/${data?.id}`,data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    
    getAllcart(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/cart/get-products?page=${pageNo}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getCartCount(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/cart/get-cart-count`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deleteCart(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/cart/delete/${id}`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


}