import React from 'react'
import { NavLink } from 'react-router-dom'

function VerifyEmail() {
  return (
    <>
        <div className=" bg-[#FBFBFB] min-h-screen pt-16 pb-32">
        <div className="container flex flex-col md:justify-center mx-auto items-center rounded-lg p-6 md:max-w-3xl">
          <div>
          <div className='flex justify-center'>
                            <img src="./images/logo.png" className="" alt="mart Logo" />
                        </div>
            <h1 className="mt-6 text-[#000] lg:text-[32px] text-[28px] font-semibold text-center">Verify Your Email Address</h1>
            <p className='mt-4 text-center text-[#00000080] text-[14px] font-normal'>To start using M Mart we need to verify your email address:</p>
          </div>

          <div className='pt-10'>
            <div className='flex justify-center'>
              <NavLink to={'/sign-in'}>
                <button className='text-white bg-[#027DCB] px-[44px] py-[15px] text-[14px] rounded-[5px]'>Verify Email</button>
                </NavLink>
            </div>
          <p className='mt-5 text-center text-[#00000080] text-[14px] font-normal'>An email has been sent with a link to verify your account. If you have not received anything after a few minutes , Kindly check your spam folder </p>
          </div>
         
         
        </div>
        </div>
    </>
  )
}

export default VerifyEmail