import { AxiosPromise } from "axios";
import configs from "../configs";
import { ProductsLiveApi } from "./live/productsLiveApis";
import { CartLiveApis } from "./live/cartLiveApis";



export class CartApis {
    private static productApis: CartLiveApis = new CartLiveApis();

    static createCart(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.createCart(data);
        }
    } 

    static createWishlist(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.createWishlist(data);
        }
    } 

    static getAllList(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getAllList(query);
        }
    } 

    static deleteWishlist(id:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.deleteWishlist(id);
        }
    } 

    
    static getAllcart(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getAllcart(query);
        }
    } 

    

    static getLocation(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getLocation(data);
        }
    } 

    static submitFeedback(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.submitFeedback(data);
        }
    } 


    

    static getCartCount(): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getCartCount();
        }
    } 

    static updateCart(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.updateCart(data);
        }
    } 

    static deleteCart(id:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.deleteCart(id);
        }
    } 


}