import React from 'react'
import { NavLink } from "react-router-dom";
// import { SvgElement, icontypesEnum } from '../../assets/svgElement';

function PwordResetSucces() {
  return (
    <>
    {/* <!-- Start block --> */}
    <section className="bg-[#FBFBFB] min-h-screen pt-16 pb-32">
        <div className="container flex flex-col md:justify-center mx-auto items-center rounded-lg p-6 md:max-w-3xl">
        
        <div>
            <div className='flex justify-center'>
              <img src="./images/logo.png" className="" alt="mart Logo" />
            </div>
            <h1 className="mt-6 text-[#000] lg:text-[32px] text-[28px] font-semibold text-center">  All done!</h1>
            <p className='mt-4 text-center text-[#00000080] text-[14px] font-normal'> Your password has been reset.</p>
          </div>

        
           

            <div className="mt-20">
              <form>

               <NavLink to={"/sign-in"} className=" ">
                  <button
                    type="button"
                    className="w-full text-white bg-[#027DCB] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Continue to Login
                  </button>
                </NavLink>
               
              </form>

             
            </div>
        
        </div>
      </section>

      {/* <!-- End block --> */}
    </>
  )
}

export default PwordResetSucces