import React, { useEffect, useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApis } from '../../apis/paymentApis';
import { Oval } from 'react-loader-spinner'
import { AdminApis } from "../../apis/adminApi";



function VerifyPayment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [subValue, setSubValue] = React.useState(1);

  const [completed, setCompleted] = React.useState(false);




  React.useEffect(() => {
    if (searchParams.get('status') == 'cancelled') {
      navigate('/view-cart');
    }
  }
  );



  if (searchParams.get('tx_ref')) {
    let data
    if (searchParams.get('status') == 'cancelled') {
      navigate('/view-cart');
    } else {
      PaymentApis.getProdutCallback(searchParams.get('tx_ref')).then(
        (response: any) => {
          if (response?.data) {
            // navigate('/wallet');
            if (response?.data?.success === true) {
              console?.log(response?.data)
              setCompleted(true)
              // navigate('/view-cart');
            }
          } else {
            // toast.warn('Invalid Login Credentials');
          }
        }
      ).catch(function (error: any) {
        // handle error
        navigate('/view-cart');
        console.log(error.response.data);
      }).finally(() => {
        
        // toast.error("No Internet Connection");

      });
    }

  }else{
    navigate('/view-cart');
  }

  return (
    <>

      {completed ?
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 text-green-500 mx-auto mb-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.707a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <h2 className="text-2xl font-bold mb-2">Payment Successful!</h2>
            <p className="text-gray-700 mb-10">Thank you for your purchase.</p>
            {/* <div className="text-left mb-4">
        <p className="text-gray-700"><strong>Transaction ID:</strong> 123</p>
        <p className="text-gray-700"><strong>Amount Paid:</strong> $2000</p>
      </div> */}
            <NavLink
              to={'/'}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Go to Home
            </NavLink>
          </div>
        </div>

        :

        <div className="flex flex-col">

          <div className="flex justify-center mt-[50vh]" >
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#E04343"
              secondaryColor="#FCBF94"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />


          </div>


          <span className="flex justify-center ">Please Wait!!!</span>

        </div>

      }





    </>
  );
}

export default VerifyPayment;
