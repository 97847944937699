import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class PaymentLiveApis extends AxiosGlobal{
    

    
    payForProduct(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/make-payment`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    payAtStore(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/pay-at-store`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    topupWallet(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/credit-user-wallet`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

    payFromWallet(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/pay-from-wallet`, data,{
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

  
    getProdutCallback(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/callback?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 
    
    getWalletTopupCallback(reference: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/payment/verify_user_wallet?reference=${reference}`, {
            headers: { "Content-Type": "aplication/json", 'mode': 'no-cors', "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    } 

}