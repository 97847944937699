
import React, { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';
import { Oval } from 'react-loader-spinner'
import InputColor from 'react-input-color';
import { CategoryApis } from "../../apis/CategoryApis";

// components



export default function CardDeals() {
  const navigate = useNavigate();
  const [total, setTotal] = useState([]);
  const [deals, setDeals] = useState([]);


  let [dealName, setDealName] = React.useState('');
  let [percentDiscount, setPercentDiscount] = React.useState('');
  let [cat1, setCat1] = React.useState('');
  let [cat2, setCat2] = React.useState('');
  let [cat3, setCat3] = React.useState('');
  let [cat4, setCat4] = React.useState('');
  let [startDate, setStartDate] = React.useState('');
  let [endDate, setEndDate] = React.useState('');


  React.useEffect(() => {
    CategoryApis.getCategory('').then(
      (response) => {
        if (response?.data) {
          setTotal(response?.data?.data)
        }
      }
    )


    CategoryApis.getDeals('').then(
      (response) => {
        if (response?.data) {
          setDeals(response?.data?.data)
          setDealName(response?.data?.data?.name)
          setPercentDiscount(response?.data?.data?.percent_discount)
          setCat1(response?.data?.data?.cat_1)
          setCat2(response?.data?.data?.cat_2)
          setCat3(response?.data?.data?.cat_3)
          setCat4(response?.data?.data?.cat_4)
          setStartDate(response?.data?.data?.start_date)
          setEndDate(response?.data?.data?.end_date)
        }
      }
    )

  }, []);


  const [loader, setLoader] = React.useState(false);






  // const UpdateMarketLink = React.useCallback(
  //   (e) => {
  //     // console?.log(color.hex)
  //     e.preventDefault();
  //     const formData = new FormData()
  //     formData.append('link_name', marketLinkData?.link_name)
  //     formData.append('brand_primary_color', (color?.hex) ? color?.hex : marketLinkData?.brand_primary_color)
  //     formData.append('brand_description', brandDescription == '' ? marketLinkData?.brand_description : brandDescription)
  //     formData.append('facebook_url', facebookUrl == '' ? marketLinkData?.facebook_url : facebookUrl)
  //     formData.append('instagram_url', instagramUrl == '' ? marketLinkData?.instagram_url : instagramUrl)
  //     formData.append('tiktok_url', tiktokUrl == '' ? marketLinkData?.tiktok_url : tiktokUrl)
  //     formData.append('brand_logo', logoImg)
  //     formData.append('brand_logo_id', logoImg)
  //     formData.append('id', marketLinkData?.id)

  //     AdminApis.updateMarketLink(formData).then(
  //       (response) => {
  //         if (response?.data) {
  //           // console.log(response?.data)
  //           setRefresh(!refresh)
  //           setToggleEditMarketLink(false);
  //           setBrandDescription('')
  //           setFacebookUrl('')
  //           setInstagramUrl('')
  //           setTiktokUrl('')
  //           setLogoImg("No selected file")
  //           setColor('');
  //           toast.success(response?.data?.message);
  //         } else {
  //           toggleModal()
  //           toast.error(response?.response?.data?.message);

  //         }

  //         // toast.success(response?.data?.message);
  //       }
  //     ).catch(function (error) {
  //       // handle error
  //       // console.log(error.response);
  //       toast.error(error.response.data.message);
  //     })
  //   },
  //   [checkLink, color, brandDescription, facebookUrl, instagramUrl, tiktokUrl, refresh, logoImg]
  // );


  const createProduct = React.useCallback(
    (e) => {
      e.preventDefault();


      setLoader(true)
      const formData = {
        "name":dealName,
        "percent_discount":percentDiscount,
        "cat_1":cat1,
        "cat_2":cat2,
        "cat_3":cat3,
        "cat_4":cat4,
        "start_date":startDate,
        "end_date":endDate,
        'id':1
      }
      CategoryApis.createDeals(formData).then(
        (response) => {
          if (response?.data) {
            setLoader(false)
            toast.success(response?.data?.message);
            // navigate('/admin/deals');
          } else {
            // toggleModal()
            toast.error(response?.response?.data?.message);
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        setLoader(false)
        toast.error(error.response.data.message);
      }).finally(function () {
        setLoader(false)
      })

    },
    [dealName,percentDiscount,cat1,cat2,cat3,cat4,startDate,endDate]
  );


  return (
    <>
      <form onSubmit={createProduct} className="pb-32 sm:px-3">

        <div className="md:flex md:justify-end">
          {/* <div className="bg-blue-100 md:w-2/5 rounded-lg m-1 p-2">
            <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className=" text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span><br />  ATTENTION : Please kindly note that the market link is the link where your customers will see the list of your products</span>
          </div> */}




        </div>


        <div className={"" + (loader ? 'shadow animate-pulse ' : '')}>
          <div className="mb-10">
            <div className=" lg:px-32 lg:w-5/5">

              <div className="grid md:grid-cols-2 gap-2">
                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Deal Name</label>
                  <input required type="text" defaultValue={dealName} onChange={(e) => setDealName(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Deals Name" />
                </div>

                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Percent discount</label>
                  <input required type="number" defaultValue={percentDiscount} onChange={(e) => setPercentDiscount(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Eg.10,20..." />
                </div>

              </div>

              <div className="grid md:grid-cols-2 gap-2">

                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category 1</label>
                  <select id="gender" defaultValue={cat1} onChange={e => setCat1(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                    <option selected value={cat1}>{cat1}</option>
                    <option  value=''>Select</option>
                    {total?.data?.map(
                      (datas, index) => (
                        <option value={datas?.cat_name}>{datas?.cat_name}</option>

                      )
                    )
                    }
                  </select>

                </div>


                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category 2</label>
                  <select id="gender" defaultValue={cat2} onChange={e => setCat2(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                  <option selected value={cat2}>{cat2}</option>
                  <option  value=''>Select</option>
                    {total?.data?.map(
                      (datas, index) => (
                        <option value={datas?.cat_name}>{datas?.cat_name}</option>

                      )
                    )
                    }
                  </select>

                </div>



                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category 3</label>
                  <select id="gender" defaultValue={cat3} onChange={e => setCat3(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                  <option selected value={cat3}>{cat3}</option>
                  <option  value=''>Select</option>
                    {total?.data?.map(
                      (datas, index) => (
                        <option value={datas?.cat_name}>{datas?.cat_name}</option>

                      )
                    )
                    }
                  </select>

                </div>


                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category 4</label>
                  <select id="gender" defaultValue={cat4} onChange={e => setCat4(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">
                  <option selected value={cat4}>{cat4}</option>
                  <option  value=''>Select</option>
                    {total?.data?.map(
                      (datas, index) => (
                        <option value={datas?.cat_name}>{datas?.cat_name}</option>

                      )
                    )
                    }
                  </select>

                </div>


              </div>


              <div className="grid md:grid-cols-2 gap-2">


                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Start Date </label>
                  <input type="date" defaultValue={startDate} onChange={(e) => setStartDate(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="ML and G e.g 500g" />
                </div>

                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">End Date </label>
                  <input type="date" defaultValue={endDate} onChange={(e) => setEndDate(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="ML and G e.g 500g" />
                </div>

              </div>






              <div className="flex justify-center gap-2 mt-5">


                <button
                  type="submit"
                  className=" text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-10 mt-10 py-2.5 text-center "
                >
                  Update
                </button>

                <Oval
                  height={40}
                  width={40}
                  color="#0071BC"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  ariaLabel='oval-loading'
                  secondaryColor="#96cff6"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />

              </div>

            </div>





          </div>




          {/* second Div */}

        </div>


      </form>


      {/* CREATE MARKET LINK */}







      {/* DELETE MARKET LINK */}



      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
