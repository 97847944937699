import { AxiosPromise } from "axios";
import configs from "../configs";

import { AuthLiveApis } from "./live/authLiveApis";
import { PaymentLiveApis } from "./live/paymentLiveApis";



export class PaymentApis {
    private static paymentApis: PaymentLiveApis = new PaymentLiveApis();
    
    

    static payForProduct(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.payForProduct(pageNo);
        }
    } 

    static payAtStore(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.payAtStore(data);
        }
    } 

    static payFromWallet(data:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.payFromWallet(data);
        }
    } 

    
    static getProdutCallback(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.getProdutCallback(reference);
        }
    } 

    static getWalletTopupCallback(reference:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.paymentApis.getWalletTopupCallback(reference);
        }
    } 

    

   
}